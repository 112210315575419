<template>
  <div class="relative">
    <base-input
      inset
      with-label
      :label="label"
      type="text"
      :id="`kode_${officeType}`"
      :placeholder="placeholder || officeTypePlaceholder"
      :disabled="disabled"
      :required="required"
      v-model="office.searchCode"
      @change="onSearchOffice"
    />
    <button
      v-if="withModal && !disabled"
      type="button"
      class="absolute right-4 top-6 cursor-pointer text-gray-400"
      @click="onOpenOfficeModal"
    >
      <Icon class="h-5 w-5" icon="heroicons:magnifying-glass-circle-20-solid" />
    </button>

    <office-table-modal
      v-if="withModal"
      :visible="visibleOfficeModal"
      :office-type="officeType"
      @close="visibleOfficeModal = false"
      @click-office="onChangeOffice"
    />

    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import OfficeTableModal from './office-table-modal.vue';
import Teleport from 'vue2-teleport';
import { mapActions } from 'vuex';

export default {
  components: { OfficeTableModal, Teleport },
  mixins: [requestMixin],
  props: {
    value: Object,
    withArea: Boolean,
    withCurrentWarehouse: Boolean,
    withStockistCenterBalance: Boolean,
    withOrderBalance: Boolean,
    withAddress: Boolean,
    withUpline: Boolean,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    officeType: {
      type: String,
      default: 'stockist_center',
    },
    withModal: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: 'search',
    },
  },
  emits: ['input', 'change', 'search'],
  data() {
    return {
      loading: false,
      visibleOfficeModal: false,
    };
  },
  computed: {
    office: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    label() {
      return {
        stockist_center: 'Kode Stockist Center',
        supplier: 'Kode Supplier',
        pusat: 'Kode Pusat',
        kantor_pelayanan: 'Kode Kantor Pelayanan',
        stockist: 'Kode Stockist',
        member: 'Kode Mitra Usaha',
      }[this.officeType];
    },
    officeTypePlaceholder() {
      return {
        stockist_center: 'SC1',
        supplier: 'HMI',
        pusat: 'PUSAT',
        kantor_pelayanan: 'KP1',
        stockist: 'AB.001',
        member: 'N-1',
      }[this.officeType];
    },
    officeCategoryId() {
      return {
        stockist_center: 3,
        supplier: 6,
        pusat: 1,
        kantor_pelayanan: 2,
        stockist: 4,
        member: 5,
      }[this.officeType];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpenOfficeModal() {
      this.visibleOfficeModal = true;
    },
    async onChangeOffice(office) {
      this.office.searchCode = office.attributes.code;

      await this.onSearchOffice();

      this.visibleOfficeModal = false;
    },
    async onSearchOffice() {
      this.loading = true;

      const officeFields = [
        ...(this.withStockistCenterBalance
          ? ['restock_balance', 'multiplied_deposit_balance']
          : []),
        ...(this.withOrderBalance ? ['order_balance_available'] : []),
        ...(this.withCurrentWarehouse ? ['current-warehouse'] : []),
        ...(this.withAddress ? ['address'] : []),
        ...(this.withArea ? ['area'] : []),
        ...(this.withUpline ? ['referred_by', 'referred_by_name'] : []),
      ].join(',');
      const simpleOfficeFields = [
        ...new Set([
          ...(this.withCurrentWarehouse ? ['office'] : []),
          ...(this.withArea ? ['office'] : []),
          'code',
          'name',
          'office_id',
        ]),
      ].join(',');
      const include = [
        ...(this.withCurrentWarehouse ? ['office.current-warehouse'] : []),
        ...(this.withArea ? ['office.area'] : []),
      ].join(',');

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'filter[office_category_id]': this.officeCategoryId,
          'filter[code]': this.office.searchCode.split(' ')[0],
          ...(this.withCurrentWarehouse
            ? {
                'fields[warehouses]': 'code',
              }
            : {}),
          ...(this.withArea
            ? {
                'fields[areas]': 'code',
              }
            : {}),
          'fields[offices]': officeFields,
          'fields[simple-offices]': simpleOfficeFields,
          include: include,
        },
      });

      if (err || !res.data.length) {
        if (this.mode === 'search') {
          this.office.searchCode = this.office.originalCode;

          this.createAlert({
            data: 'Kode tidak ditemukan',
            status: 'error',
          });
        } else if (this.mode === 'input') {
          this.office.id = null;
          this.office.name = null;
          this.office.code = this.office.searchCode;
          this.office.originalCode = this.office.searchCode;
        }
      } else {
        const simpleOffice = res.data[0];

        this.office.id = simpleOffice.attributes.office_id;
        this.office.code = simpleOffice.attributes.code;
        this.office.searchCode = simpleOffice.attributes.code;
        this.office.originalCode = simpleOffice.attributes.code;
        this.office.name = simpleOffice.attributes.name;

        if (this.withAddress) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );

          this.office.address = office.attributes.address;
        }

        if (this.withCurrentWarehouse) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );
          const currentWarehouse = this.getSingleIncluded(
            res,
            office.relationships['current-warehouse'].data.id
          );

          this.office.currentWarehouse.id = currentWarehouse.id;
          this.office.currentWarehouse.code = currentWarehouse.attributes.code;
        }

        if (this.withArea) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );
          const area = this.getSingleIncluded(
            res,
            office.relationships['area'].data.id
          );

          this.office.area.id = area.id;
          this.office.area.code = area.attributes.code;
          this.office.searchCode = `${simpleOffice.attributes.code} (${area.attributes.code})`;
          this.office.originalCode = `${simpleOffice.attributes.code} (${area.attributes.code})`;
        }

        if (this.withStockistCenterBalance) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );

          this.office.restock_balance = office.attributes.restock_balance;
          this.office.multiplied_deposit_balance =
            office.attributes.multiplied_deposit_balance;
        }

        if (this.withOrderBalance) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );

          this.office.order_balance = office.attributes.order_balance_available;
        }

        if (this.withUpline) {
          const office = this.getSingleIncluded(
            res,
            simpleOffice.relationships.office.data.id
          );

          this.office.uplineCode = office.attributes.referred_by;
          this.office.uplineName = office.attributes.referred_by_name;
        }

        this.$emit('change');
      }

      this.$emit('search');

      this.loading = false;
    },
  },
};
</script>
