<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar {{ title }}
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar {{ title }} Yang Telah Dibuat
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex justify-end gap-x-2">
            <base-input
              placeholder="Cari Kode atau Nama"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadOffices"
            />
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="offices.meta.page.total"
            :perPage="offices.meta.page.perPage"
            :currentPage="offices.meta.page.currentPage"
            :meta="offices.meta"
            cursor
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="office in offices.data"
                :key="office.id"
                :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                @click="onClickOffice(office)"
              >
                <td :class="[classes.td]">
                  <span class="font-bold text-gray-900">
                    {{ office.attributes.code }}
                  </span>
                </td>
                <td :class="[classes.td]">
                  {{ office.attributes.name }}
                </td>
                <td v-if="officeType === 'member'" :class="[classes.td]">
                  {{ office.attributes.referred_by || '-' }}
                </td>
                <td v-if="officeType !== 'member'" :class="[classes.td]">
                  {{ office.attributes.address || '-' }}
                </td>
                <td v-if="officeType !== 'member'" :class="[classes.td]">
                  {{ office.attributes.phone || '-' }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
    officeType: {
      type: String,
      default: 'stockist_center',
    },
  },
  emits: ['close', 'click-office'],
  data() {
    return {
      offices: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        {
          id: 'code',
          name: `Kode ${this.title}`,
        },
        {
          id: 'name',
          name: `Nama ${this.title}`,
        },
        ...(this.officeType === 'member'
          ? [
              {
                id: 'upline',
                name: 'Upline',
              },
            ]
          : [
              {
                id: 'address',
                name: 'Alamat',
              },
              {
                id: 'phone',
                name: 'No. Telp',
              },
            ]),
      ];
    },
    title() {
      return {
        stockist_center: 'Stockist Center',
        supplier: 'Supplier',
        kantor_pelayanan: 'Kantor Pelayanan',
        pusat: 'Pusat',
        stockist: 'Stockist',
        member: 'Mitra Usaha',
      }[this.officeType];
    },
    officeCategoryId() {
      return {
        stockist_center: 3,
        supplier: 6,
        pusat: 1,
        kantor_pelayanan: 2,
        stockist: 4,
        member: 5,
      }[this.officeType];
    },
  },
  methods: {
    async loadOffices(params) {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/offices`, {
        params: {
          ...this.requestParams,
          'page[limit]': 5,
          'fields[simple-offices]': 'code,name,office_id,referred_by',
          'filter[search]': this.filter.search,
          'filter[office_category_id]': this.officeCategoryId,
          ...params,
        },
      });

      if (!error) {
        this.offices = res;
      }

      this.loading = false;
    },
    onClickOffice(office) {
      this.$emit('click-office', office);
    },
    onOpened() {
      this.filter.search = null;

      this.loadOffices();
    },
    onPagechanged(page) {
      this.loadOffices({
        ...page,
      });
    },
  },
};
</script>
