<template>
  <base-card title="Barang">
    <datatable :columns="tableColumns" id="daftar_barang">
      <template #tbody="{ classes }">
        <tr
          :class="classes.tr"
          v-for="(orderDetail, index) in orderDetails"
          :key="index"
        >
          <td v-if="columns.includes('code')" :class="[classes.td, 'relative']">
            <search-stock-input
              ref="search_stock_input"
              :disabled="disabled"
              :original-search="orderDetail.originalProductCode"
              :type="type"
              :origin-office="originOffice"
              :origin-warehouse="originWarehouse"
              :stock-request-params="{
                'filter[seller_office_code]': originOffice.code,
                'filter[buyer_office_code]': destinationOffice.code,
                'filter[area_code]': area.code,
                'filter[buyer_type_code]': buyerType.code,
              }"
              v-model="orderDetails[index].productCode"
              @update:loading="onUpdateLoadingSearch"
              @change="
                ({ stock, product, closeModal }) =>
                  onChangeStock(index, stock, product, closeModal)
              "
            />
          </td>
          <td
            v-if="columns.includes('name') && orderDetail.id"
            :class="[classes.td]"
          >
            {{ orderDetail.productName }}
          </td>
          <td
            v-if="columns.includes('product_type') && orderDetail.id"
            :class="[classes.td]"
          >
            <base-select
              :shadow="false"
              :options="[
                { id: 'reguler', value: 'reguler', label: 'Reguler' },
                { id: 'free', value: 'free', label: 'Promo' },
              ]"
              v-model="orderDetails[index].productType"
              @change="onChangeProductType(index)"
            />
          </td>
          <td
            v-if="columns.includes('stock') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.stock | toCurrency }}
          </td>
          <td
            v-if="columns.includes('point') && orderDetail.id"
            :class="[classes.td]"
          >
            {{ orderDetail.isPoint ? 'Point' : 'Non Point' }}
          </td>
          <td
            v-if="columns.includes('qty') && orderDetail.id"
            :class="[classes.td]"
          >
            <input
              ref="order_detail_qty"
              :disabled="disabled"
              type="number"
              class="w-full border-0 p-0 placeholder-gray-200 focus:border-0 focus:ring-0 sm:text-sm"
              placeholder="Jumlah"
              v-model="orderDetails[index].qty"
              @change="onChangeQty(index)"
            />
          </td>
          <td
            v-if="columns.includes('price') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.price | toCurrency }}
          </td>
          <td
            v-if="columns.includes('total_price') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.totalPrice | toCurrency }}
          </td>
          <td
            v-if="columns.includes('qr') && orderDetail.id"
            :class="[classes.td, 'text-center']"
          >
            <template v-if="orderDetail.packageMethod === 'unique_qrcode'">
              <div
                v-if="orderDetail.canInputQr"
                class="flex justify-center gap-2"
              >
                <base-input
                  size="sm"
                  :shadow="false"
                  :classes="{ input: 'w-20' }"
                  type="text"
                  placeholder="Jumlah"
                  v-model="orderDetails[index].qrQty"
                  @change="onChangeQrQty(index)"
                />
              </div>
              <base-button v-else size="sm" color="white" @click="onSendQr(index)">Kirim QR</base-button>
            </template>
          </td>

          <td
            v-if="columns.includes('action') && orderDetail.id"
            :class="[classes.td, 'text-center']"
          >
            <svg
              v-if="!disabled"
              @click="onRemove(index)"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </td>
          <td
            v-if="columns.includes('total_weight') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.totalWeight | toFixed }}
          </td>
          <td
            v-if="columns.includes('point_value') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.pointValue | toCurrency }}
          </td>
          <td
            v-if="columns.includes('bonus_value') && orderDetail.id"
            :class="[classes.td, 'text-right']"
          >
            {{ orderDetail.bonusValue | toCurrency }}
          </td>
        </tr>
      </template>
      <template #tfoot="{ classes }">
        <tr
          :class="[
            classes.tr,
            'bg-gray-50',
            withCashbackWarning ? 'align-top' : '',
          ]"
        >
          <td :class="[classes.td]" :colspan="spanBeforeSummary">
            <div class="space-y-2 text-xs font-light italic text-red-600">
              <p v-if="totalPrice < minSpend">
                Total harga harus diatas {{ minSpend | toCurrency }} supaya
                dapat disimpan
              </p>
              <template v-if="withCashbackWarning">
                <p>Cashback kelipatan belanja {{ minCashback | toCurrency }}</p>
                <p v-if="totalPrice < minCashback">
                  Kurang
                  {{ Math.max(minCashback - totalPrice, 0) | toCurrency }} lagi
                  supaya dapat cashback
                </p>
              </template>
            </div>
          </td>
          <td :class="[classes.td, 'text-right font-semibold']">
            {{ totalPriceLabel }}
          </td>
          <td :class="[classes.td, 'text-right font-semibold']">
            {{ totalPrice | toCurrency }}
          </td>
          <template v-if="columns.includes('total_weight')">
            <td></td>
            <td :class="[classes.td, 'text-right font-semibold']">
              {{ totalWeight | toFixed }} Kg
            </td>
          </template>
          <td
            v-if="columns.includes('point_value')"
            :class="[classes.td, 'text-right font-semibold']"
          >
            {{ totalPointValue | toCurrency }}
          </td>
          <td
            v-if="columns.includes('point_value')"
            :class="[classes.td, 'text-right font-semibold']"
          >
            {{ totalBonusValue | toCurrency }}
          </td>
          <td colspan="3"></td>
        </tr>
        <template v-if="withCashback && cashback">
          <tr :class="[classes.tr, 'bg-gray-50']">
            <td :colspan="spanBeforeSummary"></td>
            <td :class="[classes.td, 'text-right font-semibold']">Cashback</td>
            <td :class="[classes.td, 'text-right font-semibold']">
              {{ cashback | toCurrency }}
            </td>
            <td colspan="2"></td>
          </tr>
          <tr :class="[classes.tr, 'bg-gray-50']">
            <td :colspan="spanBeforeSummary"></td>
            <td :class="[classes.td, 'text-right font-semibold']">Total</td>
            <td :class="[classes.td, 'text-right font-semibold']">
              {{ (totalPrice - cashback) | toCurrency }}
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
      </template>
    </datatable>

    <loading v-if="loading" />
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';
import SearchStockInput from '@/components/stock/search-stock-input.vue';

/*
originOffice: { id, code }
destinationOffice: { id, code }
originWarehouse: { id, code }
buyerType: { id, code }
area: { id, code },
orderDetails[n]: {
  id: null,
  productId: null,
  productCode: null,
  originalProductCode: null,
  productName: null,
  productType: null,
  originalProductType: null,
  stock: null,
  qty: null,
  originalQty: null,
  price: null,
  qrQty: null,
  originalQrQty: null,
  canInputQr: false,
  packageMethod: 'manual',
  totalPrice: null
}
*/
export default {
  mixins: [requestMixin],
  components: {
    SearchStockInput,
  },
  props: {
    withCashbackWarning: Boolean,
    minCashback: Number,
    value: Array,
    originOffice: {
      type: Object,
      required: true,
    },
    destinationOffice: {
      type: Object,
      required: true,
    },
    originWarehouse: Object,
    createOrderAttributes: Object,
    buyerType: {
      type: Object,
      required: true,
    },
    area: {
      type: Object,
      required: true,
    },
    withCashback: Boolean,
    cashback: Number,
    orderId: String,
    minSpend: Number,
    disabled: Boolean,
    type: {
      type: String,
      default: 'stock',
    },
    totalPriceLabel: {
      type: String,
      default: 'Total Belanja',
    },
    customStoreOrderPath: String,
    customStoreOrderDetailPath: String,
    columns: {
      type: Array,
      default: () => [
        'code',
        'name',
        'stock',
        'qty',
        'price',
        'total_price',
        'action',
      ],
    },
  },
  emits: ['input', 'order-created'],
  data() {
    return {
      loadingSearch: false,
      loadingStoreOrder: false,
      loadingStoreOrderDetail: false,
    };
  },
  computed: {
    orderDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    spanBeforeSummary() {
      if (this.columns.includes('point')) {
        return 5;
      }

      return 4;
    },
    tableColumns() {
      return [
        this.columns.includes('code')
          ? { id: 'code', name: 'Kode Barang' }
          : null,
        this.columns.includes('name')
          ? { id: 'name', name: 'Nama Barang' }
          : null,
        this.columns.includes('product_type')
          ? { id: 'product_type', name: 'Jenis Barang' }
          : null,
        this.columns.includes('stock')
          ? { id: 'stock', name: 'Stok', theadClass: 'text-right' }
          : null,
        this.columns.includes('point') ? { id: 'point', name: 'P/N' } : null,
        this.columns.includes('qty') ? { id: 'qty', name: 'Jumlah' } : null,
        this.columns.includes('price')
          ? { id: 'price', name: 'Harga', theadClass: 'text-right' }
          : null,
        this.columns.includes('total_price')
          ? { id: 'total_price', name: 'Total Harga', theadClass: 'text-right' }
          : null,
        this.columns.includes('qr')
          ? { id: 'qr', name: 'Input Qr', theadClass: 'text-center' }
          : null,
        this.columns.includes('action')
          ? { id: 'action', name: 'Aksi', theadClass: 'text-center' }
          : null,
        this.columns.includes('total_weight')
          ? {
              id: 'total_weight',
              name: 'Total Berat',
              theadClass: 'text-right',
            }
          : null,
        this.columns.includes('point_value')
          ? { id: 'point_value', name: 'PV', theadClass: 'text-right' }
          : null,
        this.columns.includes('bonus_value')
          ? { id: 'bonus_value', name: 'BV', theadClass: 'text-right' }
          : null,
      ].filter((item) => !!item);
    },
    loading() {
      return (
        this.loadingSearch ||
        this.loadingStoreOrder ||
        this.loadingStoreOrderDetail
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
    totalWeight() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + Number(orderDetail.totalWeight),
        0
      );
    },
    totalPointValue() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + Number(orderDetail.pointValue),
        0
      );
    },
    totalBonusValue() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + Number(orderDetail.bonusValue),
        0
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    checkProductWithTypeExists(productId, type) {
      return this.orderDetails.some(
        (orderDetail) =>
          orderDetail.productId === productId &&
          orderDetail.productType === type
      );
    },
    async createOrderDetail(index, productId) {
      if (!this.orderId) {
        const [, err] = await this.storeOrder();

        if (err) {
          return [null, err];
        }
      }

      const productType = this.columns.includes('product_type')
        ? this.checkProductWithTypeExists(productId, 'reguler')
          ? 'free'
          : 'reguler'
        : 'reguler';

      const [res, err] = await this.storeOrderDetail(productId, productType);

      if (!err) {
        const orderDetail = res.data;

        this.orderDetails[index].id = orderDetail.id;
        this.orderDetails[index].productId = orderDetail.attributes.product_id;
        this.orderDetails[index].productCode =
          orderDetail.attributes.product_code;
        this.orderDetails[index].originalProductCode =
          orderDetail.attributes.product_code;
        this.orderDetails[index].productName =
          orderDetail.attributes.product_name;
        this.orderDetails[index].isPoint = orderDetail.attributes.is_point;
        this.orderDetails[index].productType =
          orderDetail.attributes.product_type;
        this.orderDetails[index].originalProductType =
          orderDetail.attributes.product_type;
        this.orderDetails[index].stock = orderDetail.attributes.current_stock;
        this.orderDetails[index].qty =
          orderDetail.attributes.product_qty || null;
        this.orderDetails[index].originalQty =
          orderDetail.attributes.product_qty || null;
        this.orderDetails[index].price = orderDetail.attributes.product_price;
        this.orderDetails[index].totalPrice =
          orderDetail.attributes.total_price;
        this.orderDetails[index].totalWeight =
          orderDetail.attributes.total_weight;
        this.orderDetails[index].pointValue =
          orderDetail.attributes.point_value;
        this.orderDetails[index].bonusValue =
          orderDetail.attributes.bonus_value;
        this.orderDetails[index].qrQty = orderDetail.attributes.qr_qty;
        this.orderDetails[index].originalQrQty =
          orderDetail.attributes.qr_qty;
        this.orderDetails[index].canInputQr = false
        this.orderDetails[index].packageMethod = orderDetail.attributes.package_method

        this.$nextTick(() => {
          this.$refs.order_detail_qty[index].focus();
        });
      }

      return [res, err];
    },
    async onRemove(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];

      const [, err] = await this.request(
        this.customStoreOrderDetailPath ??
          `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'delete',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.orderDetails.splice(index, 1);

        if (
          !this.orderDetails.filter((orderDetail) => !orderDetail.id).length
        ) {
          this.pushEmptyOrderDetail();
        }
      }

      this.loadingStoreOrderDetail = false;
    },
    async onChangeProductType(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];

      const [, err] = await this.request(
        this.customStoreOrderDetailPath ??
          `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'order-details',
              id: orderDetail.id,
              attributes: {
                product_type: orderDetail.productType,
              },
            },
          },
        }
      );

      if (err) {
        this.orderDetails[index].productType =
          this.orderDetails[index].originalProductType;

        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.orderDetails[index].originalProductType =
          this.orderDetails[index].productType;
      }

      this.loadingStoreOrderDetail = false;
    },
    async onChangeQrQty(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];

      const [, err] = await this.request(
        this.customStoreOrderDetailPath ??
          `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'order-details',
              id: orderDetail.id,
              attributes: {
                qr_qty: Number(orderDetail.qrQty),
              },
            },
          },
        }
      );

      if (err) {
        this.orderDetails[index].qrQty = this.orderDetails[index].originalQrQty;

        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
          timeout: 1000
        });
      } else {
        this.orderDetails[index].originalQrQty = this.orderDetails[index].qrQty;
      }

      this.loadingStoreOrderDetail = false;
    },
    onSendQr(index) {
      this.orderDetails[index].canInputQr = true
    },
    async onChangeStock(index, stock, product, closeModal) {
      const [, err] = this.orderDetails[index].id
        ? await this.updateOrderDetailProduct(index, product.id)
        : await this.createOrderDetail(index, product.id);

      if (!err && closeModal) {
        closeModal();
      } else {
        this.orderDetails[index].productCode =
          this.orderDetails[index].originalProductCode;
      }
    },
    async onChangeQty(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];
      const qty = +orderDetail.qty

      if (qty < 1) {
        this.orderDetails[index].qty = this.orderDetails[index].originalQty
      } else {
        const [res, err] = await this.request(
          this.customStoreOrderDetailPath ??
            `/api/v1/order-details/${orderDetail.id}`,
          {
            method: 'patch',
            data: {
              data: {
                type: 'order-details',
                id: orderDetail.id,
                attributes: {
                  product_qty: +orderDetail.qty,
                },
              },
            },
          }
        );

        if (err) {
          this.orderDetails[index].qty = this.orderDetails[index].originalQty;

          this.createAlert({
            data: this.getRequestErrorMessage(err),
            status: 'error',
          });
        } else {
          this.orderDetails[index].originalQty = this.orderDetails[index].qty;
          this.orderDetails[index].stock = res.data.attributes.current_stock;
          this.orderDetails[index].totalPrice = res.data.attributes.total_price;

          if (index === this.orderDetails.length - 1) {
            this.pushEmptyOrderDetail();

            this.$nextTick(() => {
              this.$refs.search_stock_input[
                index + 1
              ].$refs.order_detail_code.focus();
            });
          }
        }
      }

      this.loadingStoreOrderDetail = false;
    },
    onUpdateLoadingSearch(value) {
      this.loadingSearch = value;
    },
    pushEmptyOrderDetail() {
      this.orderDetails.push({
        id: null,
        productCode: null,
        originalProductCode: null,
        productName: null,
        productType: null,
        isPoint: null,
        originalProductType: null,
        qrQty: null,
        originalQrQty: null,
        canInputQr: false,
        packageMethod: 'manual',
        stock: null,
        qty: null,
        originalQty: null,
        price: null,
        totalPrice: null,
        totalWeight: null,
        pointValue: null,
        bonusValue: null,
      });
    },
    async storeOrder() {
      this.loadingStoreOrder = true;

      const [res, err] = await this.request(
        this.customStoreOrderPath ?? '/api/v1/orders',
        {
          method: 'post',
          data: {
            data: {
              type: 'orders',
              attributes: {
                origin_office_id: this.originOffice.id,
                destination_office_id: this.destinationOffice.id,
                ...(this.originWarehouse
                  ? { origin_warehouse_id: this.originWarehouse.id }
                  : {}),
                ...this.createOrderAttributes,
              },
              relationships: {
                'buyer-type': {
                  data: {
                    type: 'buyer-types',
                    id: this.buyerType.id,
                  },
                },
                area: {
                  data: {
                    type: 'areas',
                    id: this.area.id,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('order-created', res);
      }

      this.loadingStoreOrder = false;

      return [res, err];
    },
    async storeOrderDetail(productId, productType = 'reguler') {
      this.loadingStoreOrderDetail = true;

      const [res, err] = await this.request(
        this.customStoreOrderDetailPath ?? '/api/v1/order-details',
        {
          method: 'post',
          data: {
            data: {
              type: 'order-details',
              attributes: {
                product_qty: 0,
                product_type: productType,
              },
              relationships: {
                order: {
                  data: {
                    type: 'orders',
                    id: this.orderId,
                  },
                },
                product: {
                  data: {
                    type: 'products',
                    id: productId,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStoreOrderDetail = false;

      return [res, err];
    },
    async patchOrderDetailProduct(orderDetail, productId) {
      this.loadingStoreOrderDetail = true;

      const [res, err] = await this.request(
        this.customStoreOrderDetailPath ??
          `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'order-details',
              id: orderDetail.id,
              attributes: {
                product_qty: 0,
              },
              relationships: {
                product: {
                  data: {
                    type: 'products',
                    id: productId,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStoreOrderDetail = false;

      return [res, err];
    },
    async updateOrderDetailProduct(index, productId) {
      const orderDetail = this.orderDetails[index];

      const [res, err] = await this.patchOrderDetailProduct(
        orderDetail,
        productId
      );

      if (!err) {
        const newOrderDetail = res.data;

        this.orderDetails[index].productCode =
          newOrderDetail.attributes.product_code;
        this.orderDetails[index].productId =
          newOrderDetail.attributes.product_id;
        this.orderDetails[index].originalProductCode =
          newOrderDetail.attributes.product_code;
        this.orderDetails[index].productName =
          newOrderDetail.attributes.product_name;
        this.orderDetails[index].productType =
          newOrderDetail.attributes.product_type;
        this.orderDetails[index].originalProductType =
          newOrderDetail.attributes.product_type;
        this.orderDetails[index].stock =
          newOrderDetail.attributes.current_stock;
        this.orderDetails[index].qty =
          newOrderDetail.attributes.product_qty || null;
        this.orderDetails[index].originalQty =
          newOrderDetail.attributes.product_qty || null;
        this.orderDetails[index].price =
          newOrderDetail.attributes.product_price;
        this.orderDetails[index].totalPrice =
          newOrderDetail.attributes.total_price;
        this.orderDetails[index].qrQty = newOrderDetail.attributes.qr_qty;
        this.orderDetails[index].originalQrQty =
          newOrderDetail.attributes.qr_qty;
        this.orderDetails[index].canInputQr = newOrderDetail.attributes.qr_qty
        this.orderDetails[index].packageMethod = newOrderDetail.attributes.package_method

        this.$nextTick(() => {
          this.$refs.order_detail_qty[index].focus();
        });
      }

      return [res, err];
    },
  },
};
</script>
