<template>
  <base-card title="Faktur">
    <loading v-if="loading" />
    <template #action>
      <base-badge :color="valid ? 'green' : 'yellow'">{{
        valid ? 'Valid' : 'Draft'
      }}</base-badge>
    </template>
    <div class="grid gap-4 sm:grid-cols-2">
      <div class="col-span-2">
        <base-input
          inset
          :disabled="disabledDate || !order.id"
          label="Tanggal"
          type="date"
          force-left
          v-model="order.date"
          @change="onChangeDate"
        />
      </div>
      <div v-if="withOriginOfficeType" class="col-span-2">
        <base-input inset label="Pilih Kantor Tujuan">
          <base-select
            inset
            expand
            :options="[
              { key: 'none', value: null, label: 'Pilih Kantor Tujuan' },
              ...originOfficeTypeOptions,
            ]"
            v-model="order.originOfficeType"
            @change="onChangeOriginOfficeType"
          />
        </base-input>
      </div>
      <template v-if="!withOriginOfficeType || order.originOfficeType">
        <template v-if="withOriginOffice">
          <search-office-input
            :disabled="originOfficeDisabled || saved"
            :office-type="order.originOfficeType"
            with-current-warehouse
            :with-area="withOriginOfficeArea"
            v-model="order.originOffice"
            @change="onChangeOriginOffice"
          />
          <base-input
            inset
            disabled
            :label="`Nama ${originOfficeLabel}`"
            :id="`nama_${order.originOfficeType}`"
            placeholder="HMI"
            :value="order.originOffice.name"
          />
        </template>
        <template v-if="withDestinationOffice">
          <search-office-input
            :disabled="saved || forceDestination"
            with-address
            :with-area="withDestinationArea"
            :with-stockist-center-balance="withStockistCenterBalance"
            :with-order-balance="withOrderBalance"
            :office-type="order.destinationOfficeType"
            :with-upline="withUpline"
            with-current-warehouse
            v-model="order.destinationOffice"
            @change="onChangeDestinationOffice"
          />
          <base-input
            inset
            disabled
            :label="`Nama ${destinationOfficeLabel}`"
            :id="`nama_${order.originOfficeType}`"
            :placeholder="`Nama ${destinationOfficeLabel}`"
            :value="order.destinationOffice.name"
          />
        </template>
        <template v-if="withOriginWarehouse">
          <base-input
            v-if="useOriginOfficeWarehouse"
            inset
            disabled
            id="kode_gudang"
            label="Kode Gudang"
            :placeholder="`Gudang ${originOfficeLabel}`"
            :value="
              !order.originOffice.id
                ? ''
                : `${order.originOffice.currentWarehouse.code} (${order.originOffice.area.code})`
            "
          />
          <search-warehouse-input
            v-else
            :disabled="saved"
            v-model="order.originWarehouse"
            @change="onChangeOriginWarehouse"
          />
          <base-input
            inset
            disabled
            id="wilayah_harga"
            label="Wilayah Harga"
            placeholder="Gudang SC1"
            :value="order.area.code"
          />
        </template>
        <template v-if="withUpline">
          <base-input
            inset
            disabled
            label="No Upline"
            placeholder="No Upline"
            :value="order.destinationOffice.uplineCode"
          />
          <base-input
            inset
            disabled
            label="Nama Upline"
            placeholder="Nama Upline"
            :value="order.destinationOffice.uplineName"
          />
        </template>
        <template v-if="withOptions">
          <div class="col-span-2">
            <base-input label="Metode Pengiriman">
              <div class="flex gap-x-6">
                <base-radio
                  v-if="!forceShipmentType || order.shipment_type === 'pickup'"
                  :disabled="saved"
                  val="pickup"
                  v-model="order.shipment_type"
                  label="Diambil"
                  @change="updateArea"
                />
                <base-radio
                  v-if="
                    !forceShipmentType || order.shipment_type === 'delivery'
                  "
                  :disabled="saved"
                  val="delivery"
                  v-model="order.shipment_type"
                  label="Dikirim"
                  @change="updateArea"
                />
              </div>
            </base-input>
          </div>
          <div
            class="col-span-2"
            v-if="
              order.shipment_type === 'delivery' && order.destinationOffice.id
            "
          >
            <base-input label="Alamat Pengiriman">
              <div class="divide-y-2">
                <p class="text-gray-600">
                  <template v-if="saved">{{
                    order.shipmentAddress.address
                  }}</template>
                  <template v-else>
                    <template v-if="order.custom_shipment_address"
                      >{{ order.shipmentAddress.address }},
                      {{ order.shipmentAddress.province.name }},
                      {{ order.shipmentAddress.city.name }},
                      {{ order.shipmentAddress.district.name }},
                      {{ order.shipmentAddress.village.name }}</template
                    >
                    <template v-else>{{
                      order.destinationOffice.address ?? '-'
                    }}</template>
                  </template>
                </p>
                <div v-if="!saved">
                  <div class="mt-3 flex gap-x-2">
                    <base-button
                      color="white"
                      size="sm"
                      @click="onClickCustomAddress"
                      >Pilih Alamat Lain</base-button
                    >
                    <base-button
                      v-if="order.custom_shipment_address"
                      color="white"
                      size="sm"
                      @click="onResetShipmentAddress"
                      >Gunakan Alamat Lama</base-button
                    >
                  </div>
                </div>
              </div>
            </base-input>
          </div>
          <div v-if="withRestockType" class="col-span-2">
            <base-input :label="restockTypeLabel">
              <div class="flex gap-x-6">
                <base-radio
                  :disabled="saved"
                  :val="true"
                  v-model="order.using_stock"
                  label="Dengan Barang"
                />
                <base-radio
                  :disabled="saved"
                  :val="false"
                  v-model="order.using_stock"
                  label="Tanpa Barang"
                />
              </div>
            </base-input>
          </div>
          <div v-if="withLoan && order.using_stock" class="col-span-2">
            <base-input label="Metode Pembayaran">
              <div class="flex gap-x-6">
                <base-radio
                  :disabled="saved"
                  :val="false"
                  v-model="order.loan"
                  label="Saldo Restock/Deposit"
                />
                <base-radio
                  :disabled="saved"
                  :val="true"
                  v-model="order.loan"
                  label="Pinjam"
                />
              </div>
            </base-input>
          </div>
        </template>
      </template>
    </div>

    <create-order-address-modal
      v-if="withOptions"
      :visible="visibleCustomAddressModal"
      v-model="order.shipmentAddress"
      @close="visibleCustomAddressModal = false"
      @change="onChangeShipmentAddress"
    />
  </base-card>
</template>

<script>
import SearchOfficeInput from '@/components/office/search-office-input.vue';
import SearchWarehouseInput from '@/components/warehouse/search-warehouse-input.vue';
import BaseRadio from '@/components/base/BaseRadio.vue';
import CreateOrderAddressModal from './create-order-address-modal.vue';
import { requestMixin } from '@/mixins/request/request';
import dayjs from 'dayjs';

/*
order: {
  area: {
    id: null,
    code: null
  },
  date: new Date,
  destinationOffice: {
    id: null,
    code: null,
    searchCode: null,
    originalCode: null,
    name: null,

    ?address: office.attributes.address,

    currentWarehouse: {
      id: null,
      code: null
    },

    area: {
      id: null,
      code: null
    },

    ?restock_balance: null,
    ?multiplied_deposit_balance: null,
  },
  custom_shipment_address: false,
  originOffice: {
    id: null,
    code: null,
    originalCode: null,
    searchCode: null,
    name: null,
    currentWarehouse: {
      id: null,
      code: null,
    },
    area: {
      id: null,
      code: null
    },
  },
  originOfficeType: null,
  destinationOfficeType: null,
  originWarehouse: {
    id: null,
    code: null,
    name: null,
    searchCode: null,
    originalCode: null,
    area: {
      id: null,
      code: null
    }
  },
  shipment_type: 'pickup',
  shipmentAddress: {
    province: {
      id: null,
      name: null
    },
    city: {
      id: null,
      name: null
    },
    district: {
      id: null,
      name: null
    },
    village: {
      id: null,
      name: null
    },
    address: null,
    area: {
      id: null,
      code: null
    },
  }
}
*/
export default {
  mixins: [requestMixin],
  components: {
    SearchOfficeInput,
    SearchWarehouseInput,
    BaseRadio,
    CreateOrderAddressModal,
  },
  props: {
    disabledDate: {
      type: Boolean,
      default: true,
    },
    originOfficeTypeOptions: {
      type: Array,
      default: () => [
        { key: 'pusat', value: 'pusat', label: 'Pusat' },
        {
          key: 'kantor_pelayanan',
          value: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
        },
        {
          key: 'stockist_center',
          value: 'stockist_center',
          label: 'Stockist Center',
        },
      ],
    },
    forceDestination: Boolean,
    value: Object,
    withDestinationArea: Boolean,
    withStockistCenterBalance: Boolean,
    withOrderBalance: Boolean,
    withUpline: Boolean,
    valid: Boolean,
    forceShipmentType: Boolean,
    withDestinationOffice: {
      type: Boolean,
      default: true,
    },
    withOriginWarehouse: {
      type: Boolean,
      default: true,
    },
    withOriginOffice: Boolean,
    withOriginOfficeType: Boolean,
    withOptions: {
      type: Boolean,
      default: true,
    },
    withRestockType: {
      type: Boolean,
      default: true,
    },
    restockTypeLabel: {
      type: String,
      default: 'Metode Restock',
    },
    withLoan: {
      type: Boolean,
      default: true,
    },
    withOriginOfficeArea: {
      type: Boolean,
      default: true,
    },
    originOfficeDisabled: Boolean,
    useOriginOfficeWarehouse: Boolean,
  },
  emits: ['input', 'change-destination-office'],
  data() {
    return {
      loading: false,
      visibleCustomAddressModal: false,
    };
  },
  computed: {
    order: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    originOfficeLabel() {
      return {
        pusat: 'Pusat',
        kantor_pelayanan: 'Kantor Pelayanan',
        supplier: 'Supplier',
        stockist_center: 'Stockist Center',
      }[this.order.originOfficeType];
    },
    destinationOfficeLabel() {
      return {
        pusat: 'Pusat',
        member: 'Mitra Usaha',
        kantor_pelayanan: 'Kantor Pelayanan',
        supplier: 'Supplier',
        stockist: 'Stockist',
        stockist_center: 'Stockist Center',
      }[this.order.destinationOfficeType];
    },
    saved() {
      return !!this.order.id;
    },
  },
  methods: {
    async onChangeDate() {
      if (this.saved) {
        this.loading = true;

        await this.request(`/api/v1/orders/${this.order.id}`, {
          method: 'patch',
          data: {
            data: {
              type: 'orders',
              id: this.order.id,
              attributes: {
                orderedAt: dayjs(this.order.date).toISOString(),
              },
            },
          },
        });

        this.loading = false;
      }
    },
    onChangeDestinationOffice() {
      this.updateArea();

      this.$emit('change-destination-office');
    },
    onChangeOriginOffice() {
      if (this.withOriginOfficeArea) {
        this.updateArea();
      }
    },
    onChangeOriginWarehouse() {
      this.updateArea();
    },
    onChangeOriginOfficeType() {
      this.order.originOffice.id = null;
      this.order.originOffice.code = null;
      this.order.originOffice.originalCode = null;
      this.order.originOffice.searchCode = null;
      this.order.originOffice.name = null;
      this.order.originOffice.currentWarehouse.id = null;
      this.order.originOffice.currentWarehouse.code = null;

      this.resetArea();
    },
    onClickCustomAddress() {
      this.visibleCustomAddressModal = true;
    },
    onChangeShipmentAddress() {
      this.order.custom_shipment_address = true;

      this.updateArea();
    },
    onResetShipmentAddress() {
      this.order.custom_shipment_address = false;

      this.order.shipmentAddress.province.id = null;
      this.order.shipmentAddress.province.name = null;

      this.order.shipmentAddress.city.id = null;
      this.order.shipmentAddress.city.name = null;

      this.order.shipmentAddress.district.id = null;
      this.order.shipmentAddress.district.name = null;

      this.order.shipmentAddress.village.id = null;
      this.order.shipmentAddress.village.name = null;

      this.order.shipmentAddress.address = null;
      this.order.shipmentAddress.area.id = null;
      this.order.shipmentAddress.area.code = null;

      this.updateArea();
    },
    resetArea() {
      this.order.area.id = null;
      this.order.area.code = null;
    },
    updateArea() {
      const destinationAreaId =
        this.order.shipmentAddress.area.id ??
        this.order.destinationOffice.area.id;
      const destinationAreaCode =
        this.order.shipmentAddress.area.code ??
        this.order.destinationOffice.area.code;
      const originWarehouse = this.useOriginOfficeWarehouse
        ? this.order.originOffice.area
        : this.order.originWarehouse.area;

      if (
        this.order.shipment_type === 'pickup' ||
        originWarehouse.code > destinationAreaCode
      ) {
        this.order.area.id = originWarehouse.id;
        this.order.area.code = originWarehouse.code;
      } else {
        this.order.area.id = destinationAreaId;
        this.order.area.code = destinationAreaCode;
      }
    },
  },
};
</script>
